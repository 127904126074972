body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
  background-color: #f4f4f4;
}


/*! Generated by Font Squirrel (https://www.fontsquirrel.com) on May 15, 2022 */



/* @font-face {
  font-family: 'apercubold_italic';
  src: url('apercu_bold_italic-webfont.woff2') format('woff2'),
       url('apercu_bold_italic-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}




@font-face {
  font-family: 'apercubold';
  src: url('apercu_bold-webfont.woff2') format('woff2'),
       url('apercu_bold-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}




@font-face {
  font-family: 'apercuitalic';
  src: url('apercu_italic-webfont.woff2') format('woff2'),
       url('apercu_italic-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}




@font-face {
  font-family: 'aperculight_italic';
  src: url('apercu_light_italic-webfont.woff2') format('woff2'),
       url('apercu_light_italic-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}




@font-face {
  font-family: 'aperculight';
  src: url('apercu_light-webfont.woff2') format('woff2'),
       url('apercu_light-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}




@font-face {
  font-family: 'apercumedium_italic';
  src: url('apercu_medium_italic-webfont.woff2') format('woff2'),
       url('apercu_medium_italic-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}




@font-face {
  font-family: 'apercumedium';
  src: url('apercu_medium-webfont.woff2') format('woff2'),
       url('apercu_medium-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}




@font-face {
  font-family: 'apercu_monoregular';
  src: url('apercu_mono-webfont.woff2') format('woff2'),
       url('apercu_mono-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;

} */

@font-face {
  font-family: 'apercu';
  src: url('/public/fonts/apercu_regular-webfont.woff2') format('woff2'),
       url('/public/fonts/apercu_regular-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

* {
  font-family: 'apercu';
}
